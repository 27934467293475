import { StyleTonalStateWrapper } from '../../../../components/StyleTonalStateWrapper';
import styles from './styles.module.scss';

export const Cta = () => {
	return (
		<div className={styles.ctaWrapper}>
		<div className={styles.ctaContent}>
		<h2>Participate in the renaissance of marketing</h2>
			<StyleTonalStateWrapper
				className="button-instance top-demo-button"
				labelText="Get a Demo"
				labelTextClassName="button-2"
				showIcon={false}
				stateProp="enabled"
				style="filled"
				href="https://calendly.com/connor-fibonaccipartner/product-demo"
			/>
		</div>
		</div>
	);
};