import styles from './slideUpText.module.scss';

export const SlideUpText = () => {
	const services = ['analyzes data', 'refines strategy', 'takes action'];
	const delaySeconds = 4;
	return (
		<div className={styles.SlideUpText}>
			<span>Leonardo</span><div className={styles.SlideUpTextContainer}>
			{services.map((item, index) => (
				<span key={`slideUpText_${index}`} className={styles.textItem} style={{animationDelay: `-${(services.length*delaySeconds)-(index*delaySeconds)}s`}}>{item}</span>
			))}
			</div>
		</div>
	)
}