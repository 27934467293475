@media (min-width: 1120px) {
.header {
  padding: 12px 15px;
 
 
}

.logoSection {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  gap: 10px;
  align-items: center;
}


}


	.logoText {
			display: block;
	}
	.header {
		padding: 12px 30px;
    background-color: white;
    width: 100%;
    height: 80px;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
   
    align-items: center;
	}
  .logoImg {
    width: 50px;
    height: 50px;
  }
  
  .logoText {
    color: #170115;
    letter-spacing: 0;
    font-family: TT Ricordi Greto Trial-Regular, Helvetica;
    font-size: 30px;
    font-weight: 400;
    line-height: normal;
    display: none;
    @media (min-width: 1120px) {
      display: block;
    }
  }
