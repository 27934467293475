import { ButtonGroup } from '../../../../components/ButtonGroup';
import headerleoimage from '../../../../../static/img/mask-group-2.png';
import { ButtonGroup } from "../../../../components/ButtonGroup";
import styles from './styles.module.scss';

export const Header = () => {
	return (
		<div className={styles.header}>
			<div className={styles.logoSection}>
				<img
					className={styles.logoImg}
					alt="Fibonacci"
					src={headerleoimage}
				/>
				<div className={styles.logoText}>Fibonacci</div>
			</div>

			<ButtonGroup
				align="justify"
				buttonLabel="Get a Demo"
				buttonStart={false}
				buttonEndHref="https://calendly.com/connor-fibonaccipartner/product-demo"
				buttonEndTarget="_blank"
				buttonVariantPrimaryClassName="header-button"
				className="header-button-group"
			/>
		</div>
	);
};