:global {
  @keyframes fadeslideUp {
    0% {
      opacity: 0;
      transform: translateY(-40%);
    }

    10% {
      opacity: 1;
      transform: translateY(-44%);
    }

    25% { /* Each word is visible for 1/3 of the animation */
      opacity: 1;
      transform: translateY(-44%);
    }

    38% {
      opacity: 0;
      transform: translateY(-60%);
    }

    100% {
      opacity: 0;
      transform: translateY(-60%);
    }
  }
}

.SlideUpText {
  flex-direction: column;
  width: 100%;
  font-size: 30px;
  line-height: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
  font-weight: normal;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  &Container {
    position: relative;
    flex: 1;
  }
	@media (min-width: 1024px) {
	flex-direction: row;
  font-size: 48px;



	}


  & .textItem {
    position: absolute;
    width: 100%;
    top: 50%;
    -webkit-animation: fadeslideUp 12s infinite;
    -moz-animation: fadeslideUp 12s infinite;
    -o-animation: fadeslideUp 12s infinite;
    animation: fadeslideUp 12s infinite;
    background: linear-gradient(90deg, #9747FF 0%, #230E21 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    left: 0;
    text-align: left;

    @media (min-width: 768px) {
      height: 55px;
    }

		@media (max-width: 1024) {
			text-align: center;
		}
  }
}