import styles from './styles.module.scss';

export const QuickStart = () => {
	return (
		<div className={styles.quickStartSection}>
			<div className={styles.quickStartSectionHeader}>
				<h2>Get started in 15 minutes</h2>
				<p>Schedule a demo, and we’ll have Leonardo running a smart campaign for you in 15 minutes</p>
			</div>
			<div className={styles.quickStartSectionContent}>
				<div>
					<svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M55.8337 16.75L25.1253 47.4583L11.167 33.5" stroke="#FEE9E7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
					</svg>

					<h4>Why do businesses use Leonardo?</h4>
					<p>Leonardo manages cross-platform campaigns, optimizing budgets and discovering novel approaches to unlock new customers. He frees teams to focus on priorities, ensuring ad dollars are used effectively.</p>
				</div>
				<div>
					<svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M55.8337 16.75L25.1253 47.4583L11.167 33.5" stroke="#FEE9E7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
					<h4>What does Leonardo actually do?</h4>
					<p>Leonardo is like a human with thousands of brains—some optimize budgets, others refine strategies, ensuring every aspect of your campaigns is managed intelligently and efficiently.</p>
				</div>
				<div>
					<svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M55.8337 16.75L25.1253 47.4583L11.167 33.5" stroke="#FEE9E7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
					</svg>

					<h4>How does Leonardo work with my team?</h4>
					<p>Leonardo manages campaigns independently but defers to your team for final approval. Through the Fibonacci platform, you can guide him like an employee to align his work with your brand.</p>
				</div>
			</div>
		</div>
	);
};