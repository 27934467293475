import "./style.scss";

import React from "react";
import { ButtonGroup } from "../../components/ButtonGroup";
import { ElementExampleLeoBrain } from "../../components/ElementExampleLeoBrain";
import { ExampleLeoBrain } from "../../components/ExampleLeoBrain";
import { FourExampleLeoBrain } from "../../components/FourExampleLeoBrain";
import { PropertyDefaultWrapper } from "../../components/PropertyDefaultWrapper";
import { StyleTonalStateWrapper } from "../../components/StyleTonalStateWrapper";
import headerleoimage from '../../static/img/mask-group-2.png';
import bigleopage1 from '../../static/img/mask-group-1.png';
import bigleobrain from '../../static/img/image-1-1.png';
import tiktok from '../../static/img/image-3.png';
import gads from '../../static/img/image-4.png';
import leofacemedium from '../../static/img/mask-group.png';
import whale from '../../static/img/image-5.png';
import takeAction from '../../static/img/take-action.png';
import { Cta, Header, QuickStart } from "./components";
import { SlideUpText } from "../../components/SlideUpText/SlideUpText";

export const Page = () => {
  return (
    <>
      <Header />



      <div className="container">
        <div className="main-section">
          <div className="leonardo-section">
            <div className="text-group">
              <p className="meet-leonardo-your">
                Meet Leonardo <br />
                your brand’s AI marketing team
              </p>
              <SlideUpText />
            </div>
            <div className="meet-group">
              <img
                className="mask-group-2"
                alt="Mask group"
                src={bigleopage1}
              />
              <StyleTonalStateWrapper
                className="button-instance top-demo-button"
                labelText="Get a Demo"
                labelTextClassName="button-2"
                showIcon={false}
                stateProp="enabled"
                style="filled"
                href="https://calendly.com/connor-fibonaccipartner/product-demo"
              />
            </div>
          </div>

          <div className="overview-section">
            <p className="text-wrapper-5">
              Leonardo is the first <span className="text-wrapper-6">AI marketing agent</span> that
              independently manages campaigns on your behalf, seamlessly analyzing your
              company data, crafting intelligent strategies, and <span className="text-wrapper-6">executing around the clock</span> to drive results.
            </p>
            <div className="analyze-section">
              <div className="analyze-overview">
                <h2 className="analyze-title">Analyzes Data</h2>
                <p className="analyze-content">
                  Leonardo does the heavy lifting, analyzing all your disparate data sources to uncover valuable connections and ensure your campaigns are data-informed and optimized for maximum ROI—so you can focus on what matters most.
                </p>
                <StyleTonalStateWrapper
                  className="button-instance top-demo-button"
                  labelText="Get a Demo"
                  labelTextClassName="button-2"
                  showIcon={false}
                  stateProp="enabled"
                  style="filled"
                  href="https://calendly.com/connor-fibonaccipartner/product-demo"
                />
              </div>
              <div className="group">
                <div className="overlap-group-2">
                  <div className="ellipse" />
                  <div className="group-2" />
                  <div className="group-3" />
                  <div className="group-4" />
                  <div className="group-5" />
                  <div className="group-6" />
                  <img className="image" alt="Image" src={tiktok} />
                  <img className="img" alt="Image" src={gads} />
                  <img
                    className="mask-group"
                    alt="Mask group"
                    src={leofacemedium}
                  />
                  <img
                    className="image-2"
                    alt="Image"
                    src={whale}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="refine-section">
            <div className="element-section">
              <div className="collaboration-section">
                <ElementExampleLeoBrain
                  className="two-example-leo-brain"
                  property1="default"
                />
                <PropertyDefaultWrapper
                  className="three-example-leo-brain"
                  property1="variant-10"
                />
              </div>
              <div className="brain-section">
                <img className="image-3" alt="Image" src={bigleobrain} />
              </div>
              <div className="forecasting-section">
                <FourExampleLeoBrain
                  className="element-example-leo-brain-instance"
                  property1="variant-4"
                />
                <ExampleLeoBrain
                  className="example-leo-brain-1"
                  property1="variant-11"
                />
              </div>
            </div>
            <div className="analyze-overview">
              <h2 className="analyze-title">Refines Strategy</h2>
              <p className="analyze-content">
                Leonardo refines strategy by analyzing your data, identifying opportunities, and optimizing campaigns for maximum impact. It ensures your approach stays dynamic, data-driven, and effective.
              </p>
              <div className="analyze-button">
                <StyleTonalStateWrapper
                  className="button-instance top-demo-button"
                  labelText="Get a Demo"
                  labelTextClassName="button-2"
                  showIcon={false}
                  stateProp="enabled"
                  style="filled"
                  href="https://calendly.com/connor-fibonaccipartner/product-demo"
                />
              </div>
            </div>
          </div>

          <div className="take-section">
            <div className="analyze-overview">
              <h2 className="analyze-title">Takes Action</h2>
              <p className="analyze-content">
                Using advanced game theory, Leonardo automatically determines and executes the best possible actions, running cross-platform campaigns for peak performance—all without you lifting a finger.
              </p>
              <StyleTonalStateWrapper
                className="button-instance top-demo-button"
                labelText="Get a Demo"
                labelTextClassName="button-2"
                showIcon={false}
                stateProp="enabled"
                style="filled"
                href="https://calendly.com/connor-fibonaccipartner/product-demo"
              />
            </div>
            <img
              className="take-action-img"
              alt="Image"
              src={takeAction}
            />
          </div>
          <QuickStart />
          <Cta />
        </div>
      </div>
    </>
  );
};
