.quickStartSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#170115 0%, #340230 39.5%, #170115 83.03%);
  color: #FFFFFF !important;
  text-align: center;
  font-family: Poppins, Helvetica;

  &Header {
    margin-bottom: 60px;

    h2 {
      margin-bottom: 25px;
      color: #f2c1d1;
      letter-spacing: 2px;
      font-family: Poppins, Helvetica;
      font-size: 30px;
      font-weight: 200;
    }

    p {
      font-size: 20px;
      font-weight: 100;
    }

    @media (min-width: 768px) {
      max-width: 900px;

      p {
        font-size: 36px;
      }

      h2 {
        font-size: 70px;
      }
    }

  }

  &Content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 15px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 4rem;
      padding: 0 30px;
    }

    div {
      flex: 1;
      display: flex;
      gap: 30px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      h4 {
        font-size: 20px;
        font-weight: 500;
      }

      p {
        font-size: 20px;
        font-weight: 100;
      }

      @media (min-width: 768px) {
        max-width: 320px;

        h4 {
          font-size: 36px;
          font-weight: 500;
        }

        p {
          font-size: 36px;
          font-weight: 100;
        }
      }
    }
  }
}