.cta {
	&Wrapper {
		display: flex;
		justify-content: center;
		background: #170115;
	}

	&Content {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #FFFFFF;
		text-align: center;
		font-family: Poppins, Helvetica;
		gap: 45px;
		padding: 60px 25px;

		h2 {
			color: #f2c1d1;
			letter-spacing: 2px;
			font-family: Poppins, Helvetica;
			font-size: 30px;
			font-weight: 200;
		}


		@media (min-width: 768px) {
			max-width: 1000px;
			padding: 0;

			p {
				font-size: 36px;
			}

			h2 {
				font-size: 70px;
			}
		}
	}



}